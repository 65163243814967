import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
id:number;
message:any;
  constructor(private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,) { }

  ngOnInit(): void {
    this.id=this._authService.sid;
  }
toggle(){
  this._authService.toggleUser({id:this.id})
      .subscribe(
        (res) => {
          this.message = res.message;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
          this.loader.close();
      window.location.reload();
        },
        (error) => {
          this.loader.close();
          this.message = error.error.message;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        }
      );
}
}
