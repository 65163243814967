import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private _authService: AuthService,
    private _router:Router,) { }

  ngOnInit(): void {
  }
  logout() {
    this._authService.logout();

    this._router.navigate(['/sign-in']);

  }
}
