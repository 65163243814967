<mat-nav-list>
  <div class="logo py-4">
    <img src="assets/logo.png" alt="logo" class="logo">
  </div>
  <a mat-list-item routerLinkActive="list-item-active" [routerLinkActiveOptions]="{exact: true}"
    routerLink="/dashboard">
    <mat-icon class="position fist">home</mat-icon> Home
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/alluser">
    <mat-icon class="position six">people</mat-icon> Users
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/allcomp">
    <mat-icon class="position secnd">turned_in</mat-icon> Company
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/allpitch">
    <mat-icon class="position secnd">turned_in</mat-icon> Pitches
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/allCampaign">
    <mat-icon class="position third">campaign</mat-icon> Campaigns
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/allTeam">
    <mat-icon class="position seven">groups</mat-icon>Teams
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/allTags">
    <mat-icon class="position six">category</mat-icon>Utils
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/allSubscriber">
    <mat-icon class="position four">subscriptions</mat-icon>Subscriber
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/allOffers">
    <mat-icon class="position eight">subscriptions</mat-icon>Offers
  </a>


  <a mat-list-item routerLinkActive="list-item-active" routerLink="/payment">
    <mat-icon class="position fout">credit_card</mat-icon>Payments
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/backers">
    <mat-icon class="position five">attach_money</mat-icon>Manage Backers
  </a>


</mat-nav-list>