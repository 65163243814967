import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { baseURL2 } from 'src/environments/environment';

@Component({
  selector: 'app-all-investos',
  templateUrl: './all-investos.component.html',
  styleUrls: ['./all-investos.component.css']
})
export class AllInvestosComponent implements OnInit {
  page:number=1;
  totalRecords:String;
  test:any=1;
  details:any;
  message: any;
  constructor(private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _router:Router,
    private _httpClient: HttpClient,) { }

  ngOnInit(): void {
    this.loader.open();
    this.allInvestor();
  }
  mine(pagination) {
    this.test = pagination;
    this.allInvestor();
  }
  private allInvestor(){
this._authService.investor({page_no:this.test}).subscribe(res =>{
  this.message=res.message;
  this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
  this.details=res.response.data;
  this.totalRecords=res.response.total_records;
  this.loader.close();
},error=>{
this.loader.close();
this.message=error.error.message;
this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
})
  }
  toggle(idd: number) {
    this.loader.open();
    this._httpClient
      .post<any>(`${baseURL2}/toggleInvAccount`, { id: idd })
      .subscribe(
        (res) => {
          this.message = res.message;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
          this.loader.close();
          // window.location.reload();
        },
        (error) => {
          this.loader.close();
          this.message = error.error.message;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        }
      );
  }

}
