<div class="px-5">
  <div class="d-flex flex-row justify-content-between">
    <h1 class="fs-3">Users List</h1>
    <button class="btn btn-dark" (click)="download()">Download</button>
  </div>
  <table class="table table-hover">
    <thead>
      <tr>
        <th class="col align-middle">User Id</th>
        <th class="col align-middle">Name</th>
        <th class="col align-middle">Email</th>
        <th class="col align-middle">Mobile</th>
        <th class="col align-middle">User type</th>
        <th class="col align-middle">KYC</th>
        <th class="col align-middle">Source</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="
            let detail of details
              | paginate
                : {
                    id: 'foo',
                    itemsPerPage: 10,
                    currentPage: page,
                    totalItems: totalRecords
                  }
          " (click)="userDetails(detail.id)">
        <td class="">{{ detail.id}}</td>
        <td class="">{{ detail.full_name }}</td>
        <td class="">{{ detail.email }}</td>
        <td class="">{{ detail.mobile }}</td>
        <td class="">
          <ng-container *ngFor="
                let prof of detail.investor_profile;
                let faf;
                of: detail.comp_profile
              ">
            <ng-container *ngIf="
                  detail.investor_profile.length >= 1 &&
                  detail.comp_profile.length >= 1
                ">
              Founder&Investor
            </ng-container>
            <ng-container *ngIf="
                  detail.investor_profile.length > 1 ||
                  detail.comp_profile.length <= 0
                ">
              Investor
            </ng-container>
          </ng-container>
        </td>
        <td *ngIf="detail.doc_verified === 1"><span class="badge text-bg-success">Verified</span> </td>
        <td *ngIf="detail.doc_verified === 0"><span class="badge text-bg-warning">Pending</span> </td>
        <td *ngIf="detail.doc_verified === 2"><span class="badge text-bg-danger">Failed</span></td>

        <td *ngIf="detail.source === 1" class="">Email</td>
        <td *ngIf="detail.source === 2" class="">Whatsapp</td>
        <td *ngIf="detail.source === 3" class="">Linkedin</td>
      </tr>

    </tbody>
  </table>


  <!--Pagination-->
  <div fxLayout="row" fxLayoutAlign="end center" class="paging">
    <pagination-controls id="foo" class="pagg" (pageChange)="page = $event" (click)="mine(page)" maxSize="9"
      directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
      screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
  </div>
</div>