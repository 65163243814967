<div class="px-5 container-xxl" *ngFor="let detail of details">
  <div class="btn_div">
    <button class="btn_back" (click)="navigate()"><span>
        <mat-icon class="back_icon">reply</mat-icon>
      </span>Back</button>
  </div>

  <button class="green last" *ngIf="(detail.active===1)" (click)="openStatus(detail.camp_id)">Visible</button>
  <button class="red last" *ngIf="(detail.active===0)" (click)="openStatuss(detail.camp_id)">Hidden</button>

  <section fxLayout="column" class="intro_sec">
    <div class="d-flex flex-row gap-3 align-items-center">
      <img class="img-fluid rounded-circle" style="max-width: 50px" alt="Image placeholder" src="{{detail.cmp_logo}}" />
      <!-- <ion-icon name="location-outline"></ion-icon> -->
      <div class="d-flex flex-column">
        <h5 class="heading">{{detail.cmp_name}}</h5>
        <span class="text-muted">
          <p class="par">
            {{detail.city}},{{detail.state}}
          </p>
        </span>
      </div>
    </div>
    <hr />
    <div class="d-flex flex-column">
      <!-- <div class="">
        <span
          class="tw-inline-flex p-1 tw-text-[10px] tw-leading-4 tw-font-semibold tw-tracking-wide tw-border tw-uppercase"
          ng-style="{'background-color': '{{y.bgcolor}}','color':'{{y.color}}','border-color':'{{ y.bgcolor }}'}"
          ng-repeat="y in selectedCampDetails.pitchTags"># {{y.name}}
        </span>
      </div> -->
      <p class="subheading mt-3">{{detail.description}}</p>

      <div class="d-flex flex-row flex-wrap gap-3 text-muted mt-1">
        <div class="d-flex flex-row align-items-center gap-2">
          <ion-icon name="globe-outline"></ion-icon>
          <a ng-href="{{detail.cmp_website}}" target="_blank">
            <span class="par"> {{detail.cmp_website}}</span>
          </a>
        </div>

        <div class="d-flex flex-row align-items-center gap-2">
          <ion-icon name="card-outline"></ion-icon>
          <span class="par"> {{detail.companypan}} </span>
        </div>
      </div>
    </div>
  </section>

  <section>
    <!-- <div class="leftdiv" fxFlex="60">
      <div class="bg_div" style="min-height: 357px; background: url({{detail.feature_img}});
     background-size: contain;   background-repeat: no-repeat;">
      </div>
      <span class="bg"></span>
    </div>
    <div class="rightdiv" fxFlex="30">
      <mat-card class="right_card blue">
        <span class="rs mr1">&#x20B9;</span> <span class="rs">{{detail.total_raised}}</span>
        <p class="rs_p">Total fund raised</p>
        <div class="line"></div>
        <h2 class="rs rs_h">{{detail.max_raise}}</h2>
        <p class="rs_p">Maximum amount raised</p>
        <div class="line"></div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="pt1">
          <div class="bordr">
            <h4 class="sub_rs">{{detail.min_raise}}</h4>
            <p class="sub_rsp">Minimum amount Raised</p>
          </div>
          <div>
            <h4 class="sub_rs">{{detail.target_raise}}</h4>
            <p class="sub_rsp">Total Target Raised</p>
          </div>
        </div>
      </mat-card>
    </div> -->
  </section>

  <section fxLayout="row" class="header_sec">
    <div class="row row-cols-lg-2 row-cols-1 mt-4 gap-4 gap-lg-0">
      <!-- <div class="col-lg-8 col-md-full p-0" ng-if="selectedCampDetails.feature_video">
        <youtube-video class="w-100 ivid" video-url="currentVdo" player="bestPlayer">
        </youtube-video>
      </div> -->

      <div class="col-lg-8 col-md-full">
        <img src="{{detail.feature_img}}" class="w-100" alt="" />
      </div>

      <div class="col-lg-4 col-md-full border border-start-0 border-end-0">
        <!--tw-shadow-md-->
        <div class="d-flex flex-column px-5 py-3 tw-h-full">
          <div class="py-3">
            <h5 class="tw-text-xl tw-text-gray-700 tw-cursor-pointer tw-font-bold">
              ₹{{detail.total_raised}}
            </h5>
            <p class="tw-text-sm tw-font-medium tw-text-gray-600">
              {{(detail.total_raised/detail.target_raise)*100}}
              % of minimum goal raised
            </p>
          </div>

          <hr />

          <div class="py-3">
            <h5 class="tw-text-xl tw-text-gray-700 tw-cursor-pointer tw-font-bold">
              {{detail.total_investors}}
            </h5>
            <p class="tw-text-sm tw-font-medium tw-text-gray-600">
              Total investors
            </p>
          </div>

          <hr />

          <div class="py-3">
            <!-- <h5 class="tw-text-xl tw-text-gray-700 tw-cursor-pointer tw-font-bold">
              {{getDiffData(detail.end_date)}} Days
            </h5> -->
            <p class="tw-text-sm tw-font-medium tw-text-gray-600">
              Left to Invest
            </p>
          </div>
          <!-- 
          <button class="primaryBtn" ng-click="gotoPayment(selectedCampDetails.url)"
            ng-hide="!schemeStatus_helper.is_between(selectedCampDetails.start_date,selectedCampDetails.end_date)">
            Invest Now
          </button>
          <button
            class="tw-inline-flex tw-w-full tw-items-center tw-justify-center tw-py-2 tw-px-6 tw-font-medium tw-tracking-wide tw-text-white tw-transition tw-duration-200 tw-bg-rose-600 tw-rounded-lg tw-shadow-md hover:tw-bg-opacity-90 focus:tw-shadow-outline disabled:tw-bg-opacity-100 focus:tw-outline-none"
            type="button"
            ng-hide="schemeStatus_helper.is_between(selectedCampDetails.start_date,selectedCampDetails.end_date)">
            Campaign Expired
          </button> -->

          <p class="tw-text-sm tw-font-medium tw-text-center text-muted mt-2">
            ₹ {{detail.min_inv}} Minimum Investment
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="tab_sec">
    <button class="create_btn" (click)="openDialog(detail.camp_id)"><mat-icon class="crt_icon">update</mat-icon> Update
      Campaign</button>
    <mat-tab-group [color]="'accent'" animationDuration="0ms" (selectedTabChange)="onChange($event)">
      <mat-tab label="Pitch">
        <h1 class="desc_h">Description</h1>
        <p class="desc_p">{{detail.description}}</p>
        <h1 class="desc_h">Title</h1>
        <p class="desc_p" [innerHTML]='detail?.title'></p>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card class="right_card id_card">
            <span class="id_span"> Id: {{detail.id}}</span>
          </mat-card>
          <mat-card class="right_card id_card">
            <span class="id_span">Pitch Id: {{detail.pitch_id}}</span>
          </mat-card>
          <mat-card class="right_card id_card mr1">
            <span class="id_span">Poc Id: {{detail.poc_id}}</span>
          </mat-card>
        </div>
      </mat-tab>

      <mat-tab label="Details">
        <mat-progress-bar mode="buffer" *ngIf="isLoading" [color]="'accent'"></mat-progress-bar>
        <h1 class="desc_h">Deck Info</h1>
        <p class="desc_p" [innerHTML]="detail?.deck_info"></p>
        <div class="log_div">
          <h1 class="desc_h">Logs</h1>
          <div *ngFor="let log of logsData">
            <mat-card class="right_card mt1 pb0 log_card">
              <p *ngIf="(log.active===1)"><button class="green left_ali">Active</button>
              </p>
              <p *ngIf="(log.active===0)"><button class="red left_ali">Inactive</button></p>
              <div fxLayout="column" class="blk_div">
                <div fxLayout="row">
                  <span class="mr1 id_span">Id: {{log.id}}</span>
                  <p class="fs_p">{{log.created_by_name}}</p>
                </div>
                <div fxLayout="row" class="mt2">
                  <p class="fs_sp mr1">
                    <mat-icon class="third">notifications</mat-icon>Camp.Id
                    {{log.camp_id}}
                  </p> <span class="fs_sp">
                    <mat-icon class="third">date_range</mat-icon> Created:
                    {{log.create_date}}
                  </span>
                </div>
                <p class="value_p">
                  <mat-icon class="third">comment</mat-icon>{{log.value}}
                </p>
              </div>
            </mat-card>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Comments">
        <mat-progress-bar mode="buffer" *ngIf="isLoading" [color]="'accent'"></mat-progress-bar>
        <div class="mt1">
          <mat-card class="cmnt_card "
            *ngFor="let cmnt of cmnts | paginate: {id: 'fool', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="column">
                <div class="cmnt_img">
                  <img src="{{cmnt.profile_img}}" alt="" class="imgn">
                  <span class="cmnt_name">{{cmnt.comment_by}}</span>
                </div>

                <div class="cmnt_cmt">{{cmnt.comment}}</div>
              </div>
              <div fxLayout="column">
                <div class="cmnt_date">{{cmnt.create_date}} </div>
                <div class="mt2"> <button class="dlt_btn" (click)="delete(cmnt.id)">Delete</button></div>
              </div>
            </div>
          </mat-card>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" class="paging">
          <pagination-controls id="fool" class="pagg" (pageChange)="page =$event" (click)="comment(page)" maxSize="9"
            directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
        </div>
      </mat-tab>

      <mat-tab label="Rewards">
        <mat-progress-bar mode="buffer" *ngIf="isLoading" [color]="'accent'"></mat-progress-bar>

        <h1 class="desc_h">Rewards</h1>
        <div *ngIf="showAlert">
          <div class="alert_div">Reward are not set for this campaign.</div>
        </div>

        <div class="grid my-5">
          <mat-card class="" *ngFor="let reward of rewardz">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <span class="rew mr1">&#x20B9; {{reward.min_amount}}</span>
              <span class="badge text-bg-success" role="button" *ngIf="(reward.active===1)"
                [matMenuTriggerFor]="menu">Active</span>
              <mat-menu #menu="matMenu">
                <p class="p_status">Are you sure to change the status ?</p>
                <div fxLayout="row" fxLayoutAlign="end end">
                  <button mat-button class="cbtn">Cancel</button>
                  <button cdkFocusInitial (click)="toggle(reward.id)" class="btn">
                    Proceed
                  </button>
                </div>
              </mat-menu>

              <span class="badge text-bg-danger" *ngIf="(reward.active===0)" [matMenuTriggerFor]="menu">Inctive</span>
              <mat-menu #menu="matMenu">
                <p class="p_status">Are you sure to change the status ?</p>
                <div fxLayout="row" fxLayoutAlign="end end">
                  <button mat-button mat-dialog-close class="cbtn">Cancel</button>
                  <button [mat-dialog-close]="true" cdkFocusInitial (click)="toggle(reward.id)" class="btn">
                    Proceed
                  </button>
                </div>
              </mat-menu>
            </div>

            <div class="my-3">
              <span><strong> {{reward.instruments}} :</strong></span>&nbsp;
              <span>{{reward.lmt - reward.is_registered}}/{{reward.lmt}}</span>
            </div>

            <div class="d-flex flex-row gap-2">
              <button class="btn btn-warning" (click)="openReward(reward.id)">Edit</button>
              <button class="btn btn-success" *ngIf="(reward.active===0)" [matMenuTriggerFor]="menu">Set Active</button>
              <button class="btn btn-danger" *ngIf="(reward.active===1)" [matMenuTriggerFor]="menu">Set
                Inactive</button>
              <button class="btn btn-dark">Delete</button>
            </div>
          </mat-card>
        </div>
      </mat-tab>

      <mat-tab label="Transaction">
        <mat-progress-bar mode="buffer" *ngIf="isLoading" [color]="'accent'"></mat-progress-bar>
        <mat-card class=" table_card ">
          <div style="overflow-x:auto;">
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th> Id </th>
                  <th> Name </th>
                  <th> Created </th>
                  <th> Last Updated </th>
                  <th> State </th>
                  <th> Status </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let ctdetail of ctdetails | paginate: {id: 'foo', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }">
                  <td><img src="{{ctdetail.profile_img}}" alt="" width="45px"></td>
                  <td> {{ctdetail.id}} </td>
                  <td> {{ctdetail.first_name}}{{ctdetail.last_name}} </td>
                  <td> {{ctdetail.date_created}} </td>
                  <td> {{ctdetail.last_updated}} </td>
                  <td> {{ctdetail.state}} </td>
                  <td *ngIf="(ctdetail.active===1)"><button class="green ">Active</button>
                  </td>
                  <td *ngIf="(ctdetail.active===0)"><button class="red ">Inactive</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" class="paging">
            <pagination-controls id="foo" class="pagg" (pageChange)="page =$event" (click)="mine(page)" maxSize="9"
              directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
          </div>
        </mat-card>
      </mat-tab>

      <mat-tab label="Updates">
        <mat-progress-bar mode="buffer" *ngIf="isLoading" [color]="'accent'"></mat-progress-bar>
        <mat-card class=" cmnt_card pb3">
          <div fxLayout="row" *ngFor="let cupdate of cupdates" class="border_div">
            <div class="cupdate_badge">
              <mat-icon class="cupdate_icon">done</mat-icon>
            </div>
            <div fxLayout="column" class="ml1">
              <span class="rew_span">{{cupdate.create_date}}</span>
              <div class="rew_span">{{cupdate.message}}</div>
            </div>
          </div>
        </mat-card>
      </mat-tab>

      <mat-tab label="DocSign">
        <mat-progress-bar mode="intermediate" *ngIf="isLoading" class="my-5"></mat-progress-bar>
        <div class="">
          <form [formGroup]="docSignForm" class="d-flex flex-column gap-3">
            <div class="d-flex flex-row gap-2">
              <mat-form-field appearance="outline" class="width">
                <mat-label>Legal Entity Name</mat-label>
                <input matInput name="id" formControlName="legal_entity_name" [(ngModel)]="detail.legal_entity">
              </mat-form-field>
              <mat-form-field appearance="outline" class="width">
                <mat-label>Legal Entity Address</mat-label>
                <input matInput name="id" formControlName="legal_entity_add" [(ngModel)]="detail.legal_entity_add">
              </mat-form-field>
            </div>

            <div class="d-flex flex-row gap-2">
              <mat-form-field appearance="outline" class="width">
                <mat-label>Authorized Signatory Name</mat-label>
                <input matInput name="id" formControlName="auth_sign_name" [(ngModel)]="detail.auth_name">
              </mat-form-field>
              <mat-form-field appearance="outline" class="width">
                <mat-label>Authorized Signatory Designation</mat-label>
                <input matInput name="id" formControlName="auth_sign_desg" [(ngModel)]="detail.auth_desg">
              </mat-form-field>
            </div>

            <div class="d-flex flex-row gap-2">
              <mat-form-field appearance="outline" class="width">
                <mat-label>Email</mat-label>
                <input matInput name="id" formControlName="email">
              </mat-form-field>
            </div>

            <div fxLayout="row">
              <img src="{{detail.profile_img}}" alt="" width="100" *ngIf="isVisible">
            </div>
            <div>
              <img [src]="imageSrc" width="100">
              <input type="hidden" formControlName="profile_img" [(ngModel)]="detail.profile_img" width="100">
              <input type="file" (change)="onGet($event)" formControlName="cImage">
              <button (click)="onUpload()" class="btn ">
                Upload
              </button>
            </div>

          </form>

          <button cdkFocusInitial class="btn btn-primary" (click)="updateDocSign()">
            Create
          </button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </section>
</div>