import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-update-pitch',
  templateUrl: './update-pitch.component.html',
  styleUrls: ['./update-pitch.component.css']
})
export class UpdatePitchComponent implements OnInit {
  id: number;
  details: any;
  message: any;
  basicPUpdateForm:FormGroup;
  file: File; // Variable to store file
  imageSrc: string;
  isVisible:boolean=true;
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loader.open();
    this.id = this._authService.sid;
    this.pitchDetails();
    this.basicPUpdateForm = this._formBuilder.group({
      id:  [this.id, [Validators.required]],
      title :  ['', [Validators.required]],
      cmp_name: ['',[Validators.required]],
      description: ['',[Validators.required]],
      deck_info: ['',[Validators.required]],
      cmp_logo: ['',[Validators.required]],
      d_logo: ['',[Validators.required]],
      hq_addr : ['',[Validators.required]],
      city : ['',[Validators.required]],
      state : ['',[Validators.required]],
      cmp_email : ['',[Validators.required]],
      companydoi : ['',[Validators.required]],
      companypan : ['',[Validators.required]],
      describehowusefunds : ['',[Validators.required]],
      facebookurl : ['',[Validators.required]],
      youtubeurl : ['',[Validators.required]],
      instagramurl : ['',[Validators.required]],
     pitchDeckFileUrl : ['',[Validators.required]],
     cmp_website : ['',[Validators.required]],
     feature_img : ['',[Validators.required]],
     f_img : ['',[Validators.required]],

  });
  }
  pitchDetails() {
    this._authService.pitchDetailsById({ pitch_id: this.id }).subscribe(res => {
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.details = res.response.data;
      this.loader.close();
    }, err => {
      this.loader.close();
      this.message = err.error.message;
      this.loader.close();
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    });
  }
  onChange(event) {
    this.file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.imageSrc = reader.result as string;
    };
    this.isVisible=false;

  }
  onUpload() {
    this.loader.open();
    this._authService.uploadImage(this.file).subscribe(
      res => {
        this.basicPUpdateForm.get('cmp_logo').setValue(res.response);
        this.basicPUpdateForm.get('feature_img').setValue(res.response);
        this.message = res.message;
        this.loader.close();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  update(){
    this._authService.updatePith(this.basicPUpdateForm.value).subscribe(res => {
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.loader.close();
      window.location.reload();
    }, err => {
      this.loader.close();
      this.message = err.error.message;
      this.loader.close();
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    });
  }
}
