import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-customoffer',
  templateUrl: './customoffer.component.html',
  styleUrls: ['./customoffer.component.css']
})
export class CustomofferComponent implements OnInit {
  message:any;
  details:any;
  page:any=1;
  test:any=1;
  totalRecords:String;

  constructor(private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _router:Router,
    private _httpClient: HttpClient,) { }

  ngOnInit(): void {
    this.loader.open();
    this.getAllOffers();
  }

  mine(pagination){
    this.loader.open();
    this.test=pagination;
    this.getAllOffers();
  }


  getAllOffers(){
    this._authService.getOffers({}).subscribe(res=>{ 
      this.message = res.message;
      this.snack.open(`Fetched All Offers`, 'OK', { duration: 4000 });
      this.details = res.response;
      this.totalRecords = res.response.length;
      this.loader.close();
    },err=>{
      this.loader.close();
      this.message = err.error.message;
      this.snack.open(`${this.message}`,'OK',{duration:4000});
    })
  }

}
