import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-update-bank',
  templateUrl: './update-bank.component.html',
  styleUrls: ['./update-bank.component.css']
})
export class UpdateBankComponent implements OnInit {
  bankUpdateForm:FormGroup;
  idd:number;
  message:any;
  constructor(private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.idd=this._authService.sid;
    this.bankUpdateForm = this._formBuilder.group({
      id:  [this.idd, [Validators.required]],
      bank_id  : ['',[Validators.required]],
      acct_name  : ['',[Validators.required]],
      acct_number  : ['',[Validators.required]],
      ifsc  : ['',[Validators.required]],
      status  : ['',[Validators.required]],

  });

  }
  create(){
    this.loader.open();
    this._authService.updateBankData(this.bankUpdateForm.value).subscribe(res=>{
      this.loader.close();
      this.message=res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      window.location.reload();
        },err=>{
          this.loader.close();
          this.message=err.error.message
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
          });
  }

}
