import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.css']
})
export class CreateTeamComponent implements OnInit {
createForm:FormGroup;
message:any;
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.createForm = this._formBuilder.group({
      name:  ['', [Validators.required]],
      mobile  : ['',[Validators.required]],
      email  : ['',[Validators.required]],
      role  : ['',[Validators.required]],
      yoj  : ['',[Validators.required]],
      fb_url  : ['',[Validators.required]],
      lnk_url  : ['',[Validators.required]],
      twt_url  : ['',[Validators.required]],
      location  : ['',[Validators.required]],
  });
  }
  create(){
    this.loader.open();
this._authService.createTeam(this.createForm.value).subscribe(res=>{
  this.message = res.message;
  this.snack.open(`${this.message}`, 'OK', { duration: 2000 });
  this.loader.close();
  window.location.reload();
}, err => {
  this.loader.close();
  this.message = err.error.message;
  this.snack.open(`${this.message}`, 'OK', { duration: 2000 });
})
  }
}
