
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { CreateCategoryComponent } from '../create-category/create-category.component';
import { UpdateCateComponent } from '../update-cate/update-cate.component';
import { DeletecategoryComponent } from './../deletecategory/deletecategory.component';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  details:any;
  message: any;
  page:number=1;
  totalRecords:String;
  test:any=1;
  constructor(private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,

   ) { }

  ngOnInit(): void {
    this.getAllCategories();
  }
  updateCat(stid) {
    this._authService.sid = stid;
    let dialogRef = this.dialog.open(UpdateCateComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openStatus(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(DeletecategoryComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  mine(pagination){
    this.loader.open();
  this.test=pagination;
  this.getAllCategories();
      }

 openDialog(){
  const dialogRef = this.dialog.open(CreateCategoryComponent);
  dialogRef.afterClosed().subscribe(result => {
  });
 }

   getAllCategories(){
    this._authService.category({page_no:this.test}).subscribe(res=>{
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.details = res.response;
      this.totalRecords=res.response.total_records;
      this.loader.close();
    }, err => {
      this.loader.close();
      this.message = err.error.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    });
  }


}
