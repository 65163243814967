import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.css']
})
export class CreateCategoryComponent implements OnInit {
  createForm:FormGroup;
  message:any;
    constructor(
      private _authService: AuthService,
      private loader: AppLoaderService,
      private snack: MatSnackBar,
      public dialog: MatDialog,
      private _formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
      this.createForm = this._formBuilder.group({
        name:  ['', [Validators.required]],
        disp_name  : ['',[Validators.required]],
        color  : ['',[Validators.required]],
        bgcolor  : ['',[Validators.required]],
    });
    }
    create(){
      this.loader.open();
  this._authService.createCategory(this.createForm.value).subscribe(res=>{
    this.message = res.message;
    this.snack.open(`${this.message}`, 'OK', { duration: 2000 });
    this.loader.close();
    window.location.reload();
  }, err => {
    this.loader.close();
    this.message = err.error.message;
    this.snack.open(`${this.message}`, 'OK', { duration: 2000 });
  });
    }

}
