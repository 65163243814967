import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { baseURL2 } from 'src/environments/environment';

import { CstatusComponent } from '../cstatus/cstatus.component';

@Component({
  selector: 'app-all-campaign',
  templateUrl: './all-campaign.component.html',
  styleUrls: ['./all-campaign.component.css']
})
export class AllCampaignComponent implements OnInit {
  page:number=1;
  totalRecords:String;
  test:any=1;
  message:any;
  details:any;
  id:number;
  text: any = '';
  constructor( private _httpClient: HttpClient,
    public dialog: MatDialog,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _router:Router,
    private _authService: AuthService,
   ) { }

  ngOnInit(): void {
    this.loader.open();
    this.getAllCampaign();
  }
  openDialog(stid) {
    this._authService.sid=stid;
    const dialogRef = this.dialog.open(CstatusComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  mine(pagination){
    this.loader.open();
  this.test=pagination;
  this.getAllCampaign();
      }
  getAllCampaign(){
    this._httpClient.post<any>(`${baseURL2}/getAllCampaign`,{page_no:this.test,search_text: this.text}).subscribe(res =>{
      this.message=res.message;
      console.log(res.response);
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.details=res.response.data;
      this.totalRecords=res.response.total_records;
      this.loader.close();
  },error=>{
    this.loader.close();
    this.message=error.error.message;
    this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    });
  }
  campDetails(id){
    this.loader.open();
    this._router.navigate(['/campaigndetails/' , id] )
    this.loader.close();
  }
updateCStatus(cid,sid){
this._authService.updateCampStatus({id:cid,status:sid}).subscribe(res =>{
  this.message=res.message;
  console.log(res);
  window.location.reload();
  this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
  this.loader.close();
},error=>{
  console.log(error);
this.loader.close();
this.message=error.error.message;
this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
});
}

}
