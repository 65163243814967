import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { baseURL2 } from 'src/environments/environment';

@Component({
  selector: 'app-alluser',
  templateUrl: './alluser.component.html',
  styleUrls: ['./alluser.component.css'],
})
export class AlluserComponent implements OnInit {
  page: number = 1;
  totalRecords: String;
  test: any = 1;
  details: any;
  message: any;
  stid: any;
  text: any = '';
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _router: Router,
    private _httpClient: HttpClient,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loader.open();
    this.getAllUser();
  }

  mine(pagination) {
    this.loader.open();
    this.test = pagination;
    this.getAllUser();
  }
  
  getAllUser() {
    this._authService
      .users({ page_no: this.test, search_text: this.text })
      .subscribe(
        (res) => {
          this.message = res.message;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
          this.details = res.response.data;
          this.totalRecords = res.response.total_records;
          this.loader.close();
        },
        (err) => {
          this.loader.close();
          this.message = err.error.message;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        }
      );
  }
  download(){
    //https://devapi.pepcorns.com/api/v1/su/download
    this._httpClient.get( baseURL2+'/download', { responseType: 'arraybuffer' }).subscribe((data) => {
      // Create blob from array buffer
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create blob URL for file
      const blobUrl = URL.createObjectURL(blob);

      // Create link element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'data.xlsx';

      // Append link element to body
      document.body.appendChild(link);

      // Click link to trigger download
      link.click();

      // Remove link element from body
      document.body.removeChild(link);
    });
      // .subscribe((data: Blob) => {
      //   const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      //   const url = window.URL.createObjectURL(blob);
      //   const a = document.createElement('a');
      //   document.body.appendChild(a);
      //   a.style.display = 'none';
      //   a.href = url;
      //   a.download = 'data.xlsx';
      //   a.click();
      //   window.URL.revokeObjectURL(url);
      // });
      
  }
  userDetails(id) {
    this._router.navigate(['userdetails', id]);
  }
}
