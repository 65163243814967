import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-all-company',
  templateUrl: './all-company.component.html',
  styleUrls: ['./all-company.component.css']
})
export class AllCompanyComponent implements OnInit {
  page: number = 1;
  totalRecords: String;
  test: any = 1;
  details: any;
  text: any = '';
  cmp_id:any;
  company:any;
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _router: Router,
    private _httpClient: HttpClient,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loader.open();
    this.getAllCompanies();
  }

  mine(pagination) {
    this.loader.open();
    this.test = pagination;
    this.getAllCompanies();
  }

  getAllCompanies(){
    this._authService.companies({page_no:this.test})
    .subscribe((res)=>{
      this.snack.open(`${res.message}`,'OK',{duration:4000});
      this.details = res.response;
      this.totalRecords = res.response.length;
      console.log(this.totalRecords );
      this.loader.close();
    },(err)=>{
      this.loader.close();
      this.snack.open(`${err.error.message}`, 'OK', { duration: 4000 });
    })
  }

  verifyAcc(stid){
    this._authService.vCompProfile({id:stid}).subscribe(
      res => {
        window.location.reload();
        this.snack.open(`${res.message}`, 'OK', { duration: 4000 });
        this.loader.close();
      }, err => {
        this.loader.close();
        this.snack.open(`${err.message}`, 'OK', { duration: 4000 });
      });
  }

  userDetails(id) {
    this._router.navigate(['userdetails', id]);
  }



}
