import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from '../app-loader.service';
import { LogoutComponent } from '../logout/logout.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  n_num:any;
  details:any;
  message: any;
  page:number=1;
  totalRecords:String;
  test:any=1;
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  constructor(private _authService: AuthService,
    public dialog: MatDialog,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _router:Router,
   ) { }

  ngOnInit(): void {
this.getAll();
  }
  openDialog() {
    const dialogRef = this.dialog.open(LogoutComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }
  mine(pagination) {
    this.loader.open();
    this.test = pagination;
    this.getAll();
  }
  getAll(){
    this._authService.allNotification({page_no:this.test}).subscribe(res=>{
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.details = res.response.data;
      this.totalRecords=res.response.total_records;
     this.n_num=res.response.total_unread;
      this.loader.close();
    }, err => {
      this.loader.close();
      this.message = err.error.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    });
  }
  // [1:newuser,2:new pitch,3:new campaign,4:new payment]
  route(typ,uid){
if(typ===1){
  this._router.navigate(['menu/dashboard/userdetails' , uid])
}else if(typ===2){
  this._router.navigate(['/menu/dashboard/pitchdetails/' , uid] )
}
else if(typ===3){
  this._router.navigate(['/menu/dashboard/campaigndetails/' , uid] )
}else if(typ===4){
  this._router.navigate(['/menu/dashboard/campaigndetails/' , uid] )
}
  }

}
