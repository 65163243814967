
<div class="router">
  <mat-card class="main_card">
    <div class="header"></div>
    <mat-card class="table_card">
      <h2 class="title">All Investors</h2>
      <div style="overflow-x:auto;">
        <table>
          <thead>
            <tr>
              <th> Id </th>
              <th> Name </th>
              <th> City </th>
              <th> Company Name </th>
              <th> Company Website </th>
              <th> Created </th>
              <th> Last Updated </th>
              <th> State </th>
              <th> Status </th>
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detail of details | paginate: {id: 'foo', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }" >
              <td> {{detail.id}} </td>
              <td> {{detail.first_name}} </td>
              <td> {{detail.city}} </td>
              <td> {{detail.cmp_name}} </td>
              <td> {{detail.cmp_url}} </td>
              <td> {{detail.date_created}} </td>
              <td> {{detail.last_updated}} </td>
              <td> {{detail.state}} </td>
              <td *ngIf="(detail.active===1)"><button class="green badge">Active</button>
              </td>
              <td *ngIf="(detail.active===0)"><button class="red badge">Inactive</button></td>
              <td>

                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example
      icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="userDetails(detail.id)">
                      <mat-icon color="warn">edit</mat-icon>
                      <span>Details</span>
                    </button>
                    <button mat-menu-item (click)="toggle(detail.id)">
                      <mat-icon>description</mat-icon>
                      <span>
                        {{ detail.active=== 1 ? 'Inactive' : 'Active'}}
                      </span>
                    </button>
                  </mat-menu>
                </td>

            </tr>
          </tbody>
        </table>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" class="paging">
        <pagination-controls id="foo" class="pagg" (pageChange)="page =$event" (click)="mine(page)" maxSize="9"
          directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
          screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
      </div>
    </mat-card>
  </mat-card>
</div>
