<div class="px-5">
  <h2>All Campaign</h2>
  <table class="table table-hover">
    <thead>
      <tr>
        <th class="col align-middle">Campaign Id </th>
        <th class="col align-middle"> Pitch Id </th>
        <th class="col align-middle"> Start Date </th>
        <th class="col align-middle">End Date </th>
        <th class="col align-middle"> Date </th>
        <th class="col align-middle"> Visibility </th>
        <th class="col align-middle">Status </th>
        <th class="col align-middle">Action </th>

      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let detail of details| paginate: {id: 'foo', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }">
        <td> {{detail.id}} </td>
        <td> {{detail.pitch_id}} </td>
        <td> {{detail.start_date}} </td>
        <td> {{detail.end_date}} </td>
        <td> {{detail.date_created}} </td>

        <td *ngIf="(detail.active===1)"><span class="badge text-bg-danger">Live</span></td>
        <td *ngIf="(detail.active===0)"><span class="badge text-bg-primary">Ready</span></td>

        <td *ngIf="(detail.status===1)"><span class="badge text-bg-warning"> Pending</span> </td>
        <td *ngIf="(detail.status===2)"><span class="badge text-bg-success"> Approved</span></td>
        <!-- <td *ngIf="(detail.status===3)"><button class="red ">Completed</button></td>
        <td *ngIf="(detail.status===4)"><button class="red ">Preclosed</button></td>
        <td *ngIf="(detail.status===5)"><button class="red ">Deleted</button></td>
        <td *ngIf="(detail.status===6)"><button class="red ">Removed By Admin</button></td> -->

        <td> <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example
          icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="campDetails(detail.id)">
              <mat-icon>description</mat-icon>
              <span>View</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="menuu">
              <mat-icon color="warn">edit</mat-icon>
              <span>
                Status
              </span>
            </button>
            <mat-menu #menuu="matMenu">
              <button mat-menu-item (click)="updateCStatus(detail.id,1)" *ngIf="(detail.status===2)">

                <span>Set to Pending</span>
              </button>
              <button mat-menu-item (click)="updateCStatus(detail.id,2)" *ngIf="(detail.status===1)">
                <mat-icon color="warn">check_box</mat-icon>
                <span>Approve Campaign</span>
              </button>
              <!-- <button mat-menu-item (click)="updateCStatus(detail.id,3)"  *ngIf="(detail.status===2)||(detail.status===1)||(detail.status===4)||(detail.status===5)||(detail.status===6)">
              <mat-icon color="warn">done</mat-icon>
              <span>Completed</span>
            </button>
            <button mat-menu-item (click)="updateCStatus(detail.id,4)" *ngIf="(detail.status===2)||(detail.status===3)||(detail.status===1)||(detail.status===5)||(detail.status===6)">
              <mat-icon color="warn">close</mat-icon>
              <span>Preclosed</span>
            </button>
            <button mat-menu-item (click)="updateCStatus(detail.id,5)" *ngIf="(detail.status===2)||(detail.status===3)||(detail.status===4)||(detail.status===1)||(detail.status===6)">
              <mat-icon color="warn">delete</mat-icon>
              <span>Deleted</span>
            </button>
            <button mat-menu-item (click)="updateCStatus(detail.id,6)" *ngIf="(detail.status===2)||(detail.status===3)||(detail.status===4)||(detail.status===5)||(detail.status===1)">
              <mat-icon color="warn">remove_circle</mat-icon>
              <span>Removed By Admin</span>
            </button> -->
            </mat-menu>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>


  <div fxLayout="row" fxLayoutAlign="end center" class="paging">
    <pagination-controls id="foo" class="pagg" (pageChange)="page =$event" (click)="mine(page)" maxSize="9"
      directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
      screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
  </div>
</div>