import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';


@Component({
  selector: 'app-all-pitch',
  templateUrl: './all-pitch.component.html',
  styleUrls: ['./all-pitch.component.css']
})
export class AllPitchComponent implements OnInit {
  text: any = '';
 details:any;
  message: any;
  page:number=1;
  totalRecords:String;
  test:any=1;
  constructor(private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _router:Router,
    private _httpClient: HttpClient,) { }

  ngOnInit(): void {
    this.loader.open();
    this.getAllPitch();

  }
  mine(pagination){
    this.loader.open();
  this.test=pagination;
  this.getAllPitch();
      }

   getAllPitch(){
    this._authService.allPitch({page_no:this.test,search_text: this.text}).subscribe(res=>{
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.details = res.response.data;
      this.totalRecords=res.response.total_records;
      this.loader.close();
    }, err => {
      this.loader.close();
      this.message = err.error.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    })
  }

  pitch(id){
    this.loader.open();
    this._router.navigate(['/pitchdetails/' , id] )
    this.loader.close();
    }



}
