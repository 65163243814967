import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { CreateTeamComponent } from './../create-team/create-team.component';

@Component({
  selector: 'app-all-team',
  templateUrl: './all-team.component.html',
  styleUrls: ['./all-team.component.css']
})
export class AllTeamComponent implements OnInit {
 details:any;
  message: any;
  page:number=1;
  totalRecords:String;
  test:any=1;
  constructor(private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _router:Router,
    private _httpClient: HttpClient,) { }

  ngOnInit(): void {
    this.loader.open();
    this.getAllTeam();

  }
  mine(pagination){
    this.loader.open();
  this.test=pagination;
  this.getAllTeam();
      }

 openDialog(){
  const dialogRef = this.dialog.open(CreateTeamComponent);
  dialogRef.afterClosed().subscribe(result => {
  });
 }

   getAllTeam(){
    this._authService.team({page_no:this.test}).subscribe(res=>{
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.details = res.response;
      this.totalRecords=res.response.total_records;
      this.loader.close();
    }, err => {
      this.loader.close();
      this.message = err.error.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    });
  }
  toggle(stid){

    this.loader.open();
this._authService.toggleTeam({id:stid}).subscribe(res=>{
  this.message = res.message;
  this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
  window.location.reload();
  this.loader.close();
}, err => {
  this.loader.close();
  this.message = err.error.message;
  this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
});
  }

}
