import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultComponent } from './AllComponents/default/default.component';
import { AuthGuard } from './Auth/Auth.guard';
import { NoAuthGuard } from './auth/noAuth.guard';
import { DashboardComponent } from './Dashboard/dashboard.component';
import { AllPitchComponent } from './AllComponents/all-pitch/all-pitch.component';
import { PichdetailsidComponent } from './AllComponents/pichdetailsid/pichdetailsid.component';
import { AllCampaignComponent } from './AllComponents/all-campaign/all-campaign.component';
import { CampaigndetailsidComponent } from './AllComponents/campaigndetailsid/campaigndetailsid.component';
import { PaymentComponent } from './AllComponents/payment/payment.component';
import { BackersComponent } from './AllComponents/backers/backers.component';
import { AlluserComponent } from './AllComponents/alluser/alluser.component';
import { UserdetailsbyIdComponent } from './AllComponents/userdetailsby-id/userdetailsby-id.component';
import { AllTeamComponent } from './AllComponents/all-team/all-team.component';
import { CategoryComponent } from './AllComponents/category/category.component';
import { SubscriberComponent } from './AllComponents/subscriber/subscriber.component';
import { CustomofferComponent } from './AllComponents/customoffer/customoffer.component';
import { AllCompanyComponent } from './AllComponents/all-company/all-company.component';


const routes: Routes = [

  { path: '',
  canActivate:[NoAuthGuard],
  children:[{
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  ]
  },
  {
    // path: 'menu/dashboard',component:DashboardComponent,
    path: '',component:DashboardComponent,
    canActivate : [AuthGuard],
    children: [
    {
      path: 'dashboard',
      component:DefaultComponent
    },
    {
      path:'allpitch',
      component:AllPitchComponent
    },
    {
      path:'pitchdetails/:id',
      component: PichdetailsidComponent
    },
    {
      path:'allCampaign',
      component: AllCampaignComponent
    },
    {
      path:'campaigndetails/:id',
      component: CampaigndetailsidComponent
    },
    {
      path:'payment',
      component: PaymentComponent
    },
    {
      path:'backers',
      component: BackersComponent
    },
    {
      path:'alluser',
      component: AlluserComponent
    },
    {
      path:'userdetails/:id',
      component: UserdetailsbyIdComponent
    },
    {
      path:'allTeam',
      component: AllTeamComponent
    },
    {
      path:'allTags',
      component: CategoryComponent
    },
    {
      path:'allSubscriber',
      component: SubscriberComponent
    },
    {
      path:'allOffers',
      component: CustomofferComponent
    },
    {
      path:'allcomp',
      component: AllCompanyComponent
    }


  ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],



exports: [RouterModule]
})
export class AppRoutingModule { }
