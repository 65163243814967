import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {
  public details: any[];
  message: any;
  constructor(private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,) { }

  ngOnInit(): void {
    this.loader.open();
    this.dashBoardStat();
  }
  private dashBoardStat() {
    this._authService.dashstat({}).subscribe(res => {
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.details = res.response;
      this.loader.close();
    }, err => {
      this.loader.close();
      this.message = err.error.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    })
  }

}
