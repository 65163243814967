import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from 'src/app/auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { CstatusComponent } from '../cstatus/cstatus.component';
import { UpdateCampaignComponent } from '../update-campaign/update-campaign.component';
import { UpdateRewardComponent } from '../update-reward/update-reward.component';

@Component({
  selector: 'app-campaigndetailsid',
  templateUrl: './campaigndetailsid.component.html',
  styleUrls: ['./campaigndetailsid.component.css'],

})
export class CampaigndetailsidComponent implements OnInit {
  id: number;
  details: any;
  message: any;
  logsData: any;
  rewardz: any;
  page: number = 1;
  totalRecords: String;
  test: any = 1;
  ctdetails: any;
  cmnts: any;
  cupdates: any;
  isLoading: boolean = false;
  showAlert:boolean = false;
  docSignForm:FormGroup;
  file: File=null; // Variable to store file
  imageSrc: string;
  isVisible:boolean=true;

  constructor(private _activeRoute: ActivatedRoute,
    private _httpClient: HttpClient,
    private _authService: AuthService,
    private loader: AppLoaderService,
    private _router: Router,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loader.open();
    this.campDetails();
    this.docSignForm = this._formBuilder.group({
      camp_id: this._activeRoute.snapshot.params['id'],
      legal_entity_name:['',[Validators.required]],
      legal_entity_add:['',[Validators.required]],
      auth_sign_name:['',[Validators.required]],
      auth_sign_desg:['',[Validators.required]],
      email:['',[Validators.required]],
      sign:['',[Validators.required]],
    })
  }
  onChange(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    if (tab ==="Comments") {
      this.isLoading = true;
      this.comments();
    } else if (tab ==="Rewards") {
      this.isLoading = true;
      this.rewards();
    } else if (tab ==="Transaction") {
      this.isLoading = true;
      this.transcationByCampId();
    }
    else if (tab ==="Updates") {
      this.isLoading = true;
      this.campUpdateById();
    }else if(tab ==="DocSign"){
      // this.isLoading = true;
    }
  }
  mine(pagination) {
    this.loader.open();
    this.test = pagination;
    this.transcationByCampId();
  }
  comment(pagination) {
    this.loader.open();
    this.test = pagination;
    this.comments();
  }
  campDetails() {
    this.id = this._activeRoute.snapshot.params['id'];
    this._authService.campDetailsById({ camp_id: this.id }).subscribe(res => {
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.details = res.response.data;
      this.logsData = res.response.logs;
      this.loader.close();

    }, err => {
      this.loader.close();
      this.message = err.error.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    })
  }
  rewards() {
    this.id = this._activeRoute.snapshot.params['id'];
    this._authService.transRewards({ camp_id: this.id, page_no: this.test }).subscribe(res => {
      this.rewardz = res.response;
      if(this.rewardz.length<=0){
        this.showAlert=true;
      }
      this.message = res.message;
      this.isLoading = false;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    }, err => {
      this.isLoading = false;
      this.loader.close();
      this.message = err.error.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    }
    )
  }
  navigate() {
    this._router.navigate(['allCampaign']);
  }
  openStatus(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(CstatusComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openStatuss(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(CstatusComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  openDialog(stid){
    this._authService.sid = stid;

    const dialogRef = this.dialog.open(UpdateCampaignComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openReward(rid){
    this._authService.sid = rid;
    const dialogRef = this.dialog.open(UpdateRewardComponent);
    dialogRef.afterClosed().subscribe(result=>{
    });
  }
  transcationByCampId() {
    this._authService.campTranscation({ camp_id: this.id, page_no: this.test }).subscribe(res => {
      this.isLoading = false;
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.ctdetails = res.response.data;
      this.totalRecords = res.response.total_records;
      this.loader.close();
    }, err => {
      this.isLoading = false;
      this.loader.close();
      this.message = err.error.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    })
  }
  comments() {
    this._authService.allComments({ camp_id: this.id, page_no: this.test }).subscribe(res => {
      this.message = res.message;
      this.cmnts = res.response.data;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.totalRecords = res.response.total_records;
      this.loader.close();
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.message = err.error.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    })
  }
  delete(did) {
    this.loader.open();
    this._authService.deleteById({ comment_id: did }).subscribe(
      res => {
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.loader.close();
        window.location.reload();
      }, err => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        window.location.reload();
      }
    );
  }
  campUpdateById() {
    this.id = this._activeRoute.snapshot.params['id'];
    this._authService.getCampUpdate({ camp_id: this.id }).subscribe(
      res => {
        this.isLoading = false;
        this.cupdates = res.response;
      }, err => {
        this.isLoading = false;;
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  toggle(tid) {
    this.loader.open();
    this._authService.toggleCampaignrewards({ reward_id: tid }).subscribe(res => {
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      window.location.reload();
      this.loader.close();
    }, err => {
      this.loader.close();
      this.message = err.error.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    })
  }
  goTo(nav){
    window.open(`https://${nav}`, "_blank");
  }

  onGet(event) {
    this.file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.imageSrc = reader.result as string;
    };
    this.isVisible=false;
  }

  onUpload() {
    this.loader.open();
    this._authService.uploadImage(this.file).subscribe(
      res => {
        this.docSignForm.get('sign').setValue(res.response);
        this.message = res.message;
        this.loader.close();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }

  updateDocSign(){
    this.loader.open();
    this._authService.updateDocSign(this.docSignForm.value).subscribe(
      res => {
        this.message = res.message;
        this.loader.close();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }


}

